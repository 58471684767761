/*
 * header.scss
 *
 * ヘッダー用コンポーネント
 */

.header {
  width: 100%;
  position: fixed;
  z-index: 101;
  transition: transform $t_fade,  background-color $t_fade;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  @include responsive((
    padding: 48 80 0,
  ));
  @include mq(sp) {
    // height: mycalcSP(160);
    height: mycalcSP(124);
    padding: 0;
  }

  @media screen and (max-width: 1750px) {
    justify-content: flex-end;
  }

  &_logo {
    flex-shrink: 0;
    position: absolute;
    opacity: 1;
    z-index: 1;
    transition: transform $t_fade;
    transform-origin: left center;
    @include responsive((
      width: 430,
      top: 48,
      left: 80,
    ));
    @include mq(sp) {
      width: mycalcSP(368);
      top: mycalcSP(32);
      left: mycalcSP(32);
      transform-origin: left top;
      transition: transform $t_fade 0s, opacity $t_fade .2s;

      .open & {
        opacity: 0;
        transform: translate(mycalcSP(40), mycalcSP(64));
        transition: transform $t_fade .4s, opacity $t_fade .8s;
      }

      .scrolled.open & {
        transform: scale(1) translate(mycalcSP(40), mycalcSP(64));
      }
    }

    &_link {
      @include flex(row, center, center, none, wrap);

      picture, svg, img {
        display: block;
        width: 100%;

        @include mq(sp) {
          max-width: none;
          height: auto;
        }
      }
    }

    .scrolled & {
      transform: scale(.75);
    }

    .loading .scrolled & {
      transform: scale(1); // ページ遷移時のループ挙動を回避
    }
  }

  &_gnav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    margin: 0;
    position: relative;
    padding-bottom: 0;
    transform: translateY(0);
    transition: transform $t_scroll;

    @media screen and (max-width: 1750px) {
      width: auto;
    }
    @media screen and (max-width: 1580px) {
      margin-right: 0;
    }

    @include mq(sp) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100vh;
      padding: mycalcSP(96 0 96);
      position: fixed;
      top: 0;
      color: $c_text;
      padding-bottom: 0;
      box-sizing: border-box;
      overflow-y: scroll;
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 1.0s $e_curtain 0s, transform 1s $e_curtain 1s;
      z-index: 9;
    }

    &_logo {
      display: none;
      opacity: 0;

      @include mq(sp) {
        width: mycalcSP(368);
        margin-left: mycalcSP(72);
        margin-bottom: mycalcSP(96);
        transform-origin: left top;
        transform: translate(mycalcSP(-40), mycalcSP(-64));
        display: block;
        transition: opacity $t_fade 1.8s, transform $t_fade 0s;

        .scrolled & {
          transform: scale(.75) translate(mycalcSP(-40), mycalcSP(-64));
        }

        .open & {
          opacity: 1;
          transition: opacity $t_fade .76s, transform $t_fade .4s;
          transform: scale(1) translate(0, 0);
        }
      }

      &_link {
        picture, svg, img {
          display: block;
          width: 100%;

          @include mq(sp) {
            max-width: none;
            height: auto;
          }
        }
      }
    }

    &_box {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      z-index: 6;
      @media screen and (max-width: 1750px) {
        margin-right: 10px;
      }
      @include mq(sp) {
        width: 100%;
        margin-right: 0;
        padding: 0;
        box-sizing: border-box;
        flex-shrink: 0;
        margin-bottom: mycalcSP(16);
      }
    }

    &_list {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      @include flex(row, center, center, none, wrap);
      @include responsive((
        border-radius: 64,
        padding: 0 64,
        margin-left: 64,
      ));
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        border-radius: 0;
        padding: mycalcSP(0 72);
        background: none;
      }

      &_item {
        transition: transform $t_fade;
        @include responsive((
          margin: 0 40,
        ));
        @include mq(sp) {
          transform: translateX(mycalcSP(-250));
          opacity: 0;
          transition: opacity .8s $e_curtain, transform .6s $e_curtain;
          width: 100%;
          margin: 0;
          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              transition-delay: .15s * $i;
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .scrolled & {
          transform: scale(.8);
        }

        &_link {
          line-height: 1;
          font-weight: 500;
          position: relative;
          transition: background-color $t_slide, color $t_scroll;
          box-sizing: border-box;
          color: $c_text;
          letter-spacing: .05em;
          @include flex(row, center, center, none, wrap);
          @include responsive((
            font-size: 32,
            line-height: 48,
            padding: 32 8,
          ));
          @include mq(sp) {
            font-weight: 400;
            justify-content: space-between;
            font-size: mycalcSP(32);
            line-height: mycalcSP(48);
            padding: 0;
            margin-bottom: mycalcSP(48);
            height: auto;
            color: $c_text;
            text-align: left;
          }

          &_txt {
            letter-spacing: inherit;
            font-weight: inherit;
          }

          .ico_add {
            display: none;
            @include mq(sp) {
              display: block;
              width: mycalcSP(48);
              height: mycalcSP(48);
            }
            &_vertical {
              @include mq(sp) {
                stroke: $c_pink;
                stroke-dasharray: 24;
                stroke-width: 0;
                fill: $c_pink;
                stroke-dashoffset: 0;
                transition: fill $t_slide .8s,
                stroke-width $t_slide .8s,
                stroke-dashoffset $t_slide;
              }
            }
          }

          &.active {
            .ico_add {
              &_vertical {
                @include mq(sp) {
                  stroke-width: .5;
                  fill: transparent;
                  stroke-dashoffset: 24px;
                  transition: fill $t_slide,
                  stroke-width $t_slide,
                  stroke-dashoffset $t_slide .8s;
                }
              }
            }
          }

          &.open {
            background: $c_gray_light;
          }

          &:before {
            content: "";
            width: 0;
            border-bottom: 1px solid $c_purple_dark;
            opacity: 0;
            bottom: 0;
            right: 0;
            display: block;
            position: absolute;
            transition: width $t_slide .1s, opacity $t_slide;
          }

          &:hover {
            &:before {
              opacity: 1;
              width: 100%;
              left: 0;
              right: auto;
            }
          }
        }
      }

      .gnav_list_parent {

        .header_gnav_list_item_link {
          position: relative;
          @include responsive((
            padding-right: 92,
          ));

          &:after {
            content: '';
            border-left: solid 2px $c_black;
            border-bottom: solid 2px $c_black;
            position: absolute;
            top: 50%;
            transition: $t_slide;
            transform: rotate(-45deg);
            box-sizing: border-box;
            @include responsive((
              width: 12,
              height: 12,
              right: 48,
              margin-top: -6,
            ));
            @include mq(sp) {
              width: mycalcSP(12);
              height: mycalcSP(12);
              right: mycalcSP(48);
              margin-top: mycalcSP(-8);
            }
          }

          &:hover:after {
          }
        }
      }
    }

    &_filter {
      position: fixed;
      width: 100%;
      height: 100%;
      background: $c_white;
      transform: translateY(110%);
      opacity: .7;
      top: 0;
      left: 0;
      z-index: 5;
      transition: .6s $e_slot .02s;

      &.open {
        transition: .6s $e_slot;
      }

      @include mq(sp) {
        display: none;
      }
    }
  }

  &_touchpoint {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 1750px) {
      position: relative;
      top: 0;
      right: 0;
    }
    @include mq(sp) {
      flex-flow: column;
      align-items: flex-start;
      padding: mycalcSP(0 72 96);
    }

    .sns {
      @media screen and (max-width: 1580px) {
        display: none;
      }
      @include mq(sp) {
        display: flex;
        order: 3;
        margin-bottom: mycalcSP(64);
        transform: translateX(mycalcSP(-250));
        opacity: 0;
        transition: opacity .8s $e_curtain .9s, transform .6s $e_curtain .9s;
      }
    }

    .btn_round {
      transition: transform $t_fade;
      transform-origin: right center;
      @include responsive((
        width: 460,
        height: 112,
        margin-left: 80,
      ));
      @media screen and (max-width: 1580px) {
        margin-left: 0;
      }
      @media screen and (max-width: 1400px) {
        border-radius: 50%;
        @include responsive((
          width: 112,
          height: 112,
        ));
      }
      @include mq(sp) {
        width: mycalcSP(610);
        height: mycalcSP(144);
        border-radius: mycalcSP(88);
        order: 1;
        margin-bottom: mycalcSP(96);
        transform: translateX(mycalcSP(-250));
        opacity: 0;
        transition: opacity .8s $e_curtain .8s, transform .6s $e_curtain .8s;
      }

      .scrolled & {
        transform: scale(.8);
      }

      &_link {
        &_txt {
          @include responsive((
            font-size: 28,
            line-height: 48,
          ));
          @media screen and (max-width: 1400px) {
            display: none;
          }
          @include mq(sp) {
            display: inline;
            font-size: mycalcSP(30);
            line-height: mycalcSP(48);
          }
        }

        .ico_ec {
          @media screen and (max-width: 1400px) {
            margin-right: 0;
          }
          @include mq(sp) {
            margin-right: mycalcSP(16);
          }
        }

        .ico_arw {
          display: none;
          @include mq(sp) {
            display: block;
          }
        }
      }
    }
    
    .btn {
      display: none;

      @include mq(sp) {
        display: block;
        order: 2;
        margin-top: 0;
        margin-bottom: mycalcSP(96);
        transform: translateX(mycalcSP(-250));
        opacity: 0;
        transition: opacity .8s $e_curtain .85s, transform .6s $e_curtain .85s;
      }
    }

    .header_gnav_bottom {
      display: none;

      @include mq(sp) {
        transform: translateX(mycalcSP(-250));
        opacity: 0;
        transition: opacity .8s $e_curtain .95s, transform .6s $e_curtain .95s;
        display: block;
        order: 4;

        &_list {
          &_item {
            font-size: mycalcSP(26);
            line-height: mycalcSP(40);
            margin-bottom: mycalcSP(40);
            text-align: left;

            a{
              color: $c_text;
              border-bottom: 0;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .ico_arw {
                transform: rotate(-45deg);
                @include responsive((
                  width: 40,
                  height: 40,
                  margin-left: 4,
                ));
                @include mq(sp) {
                  width: mycalcSP(40);
                  height: mycalcSP(40);
                  margin-left: mycalcSP(4);
                }
              }
            }
          }
        }

        .copyright {
          font-size: mycalcSP(20);
          line-height: mycalcSP(32);
          text-align: left;
        }
      }
    }
  }

  &.scrolled {
    background-color: $c_pink_light;
    transform: translateY(-24px);
    @include mq(pc) {
      transform: translateY(#{mycalc(-48)});
    }
  }

}

.hum {
  display: none;

  @include mq(sp) {
    @include flex(column, center, center, none, wrap);
    align-self: stretch;
    width: mycalcSP(112);
    height: mycalcSP(112);
    //background: $c_gray_light;ç
    color: $c_gray_dark;
    transition: .5s;
    cursor: pointer;
    font-weight: 500;
    order: 1;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    border-radius: mycalcSP(0 0 0 72);

    &:hover {
      cursor: pointer;
    }

    &_bar {
      width: mycalcSP(40);
      height: mycalcSP(26);
      margin-bottom: mycalcSP(4);
      position: relative;

      &_u,
      &_m,
      &_b {
        position: absolute;
        height: mycalcSP(2);
        background: $c_gray_dark;
        width: 100%;
        right: 0;
        border-radius: mycalcSP(10);
        transition: all .3s, background-color $p_hum;
      }

      &_u {
        top: 0;
      }

      &_m {
        top: 50%;
        margin-top: mycalcSP(-1);
      }

      &_b {
        bottom: 0;
      }
    }

    &_bg {
      content: "";
      display: block;
      position: fixed;
      background: $c_pink_light;
      //background: $c_bg_beppin-sakura;
      z-index: 2;
      transform: translate(0%, -100%);
      will-change: border-radius, transform, background;
      width: 100%;
      height: 100%;
      border-radius: mycalcSP(0 0 72 72);
      top: 0;
      right: 0;
      transform-origin: top right;

      &_sakura {
        @include minq(sp) {
          display: none;
        }
        @include mq(sp) {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: fixed;
          z-index: 2;
          transform: translate(0%, -100%);
        }

        .sakura_sp {
          @include minq(sp) {
            display: none;
          }
          @include mq(sp) {
            will-change: transform;
            transform: translate(0%, -100%);
            position: absolute;
            display: block;
            width: mycalcSP(1004);
            height: mycalcSP(1422);
            top: 50%;
            left: 50%;
            margin-left: mycalcSP(-492);
            margin-top: mycalcSP(-720);
          }
        }
      }
    }

    &_menu {
      z-index: 1;
      svg {
        width: mycalcSP(60);
        height: mycalcSP(18);
        display: block;
        transition: $t_fade;

        path {
          fill: $c_pink;
          fill-opacity: 1;
          stroke: $c_pink;
          stroke-width: 0;
          stroke-dasharray: 54;
          stroke-dashoffset: 0;
          transition: fill $t_slide 1.8s,
          fill-opacity $t_fade 1.8s,
          stroke-width $t_slide 1.8s,
          stroke-dashoffset $t_slide 1s;
        }
      }
    }

    &_close {
      @include flex(row, center, center, none, wrap);
      position: absolute;
      width: 100%;
      right: 0;
      bottom: mycalcSP(42);
      z-index: 0;

      svg {
        width: mycalcSP(58);
        height: mycalcSP(18);
        display: block;
        transition: $t_fade;

        path {
          fill: transparent;
          fill-opacity: 1;
          stroke: $c_pink;
          stroke-width: .5;
          stroke-dasharray: 54;
          stroke-dashoffset: 54px;
          transition: fill $t_slide,
          fill-opacity $t_slide,
          stroke-width $t_slide,
          stroke-dashoffset $t_slide .8s;
        }
      }
    }
  }
}

.open {
  @include mq(sp) {
    .hum {
      &_bar {
        &_u,
        &_m,
        &_b {
          background: $c_gray_dark;
        }

        &_u {
          animation: Humberger_bar_z $p_hum*1.8 $e_syu .2s forwards;
        }

        &_m {
          animation: Humberger_bar_x $p_hum*1.8 $e_syu .2s forwards;
        }

        &_b {
          animation: Humberger_bar_y $p_hum*1.8 $e_syu .2s forwards;
        }
      }

      &_bg {
        transform: translate(0%, -100%);
        animation: Humberger_bg .6s $e_curtain 0s forwards;

        &_sakura {
          transform: translate(0%, 0%);
          .sakura_sp {
            transform: translate(0%, -100%);
            animation: Humberger_bg_sakura 1s $e_fade .2s forwards;
          }
        }
      }

      &_menu path {
        fill: transparent;
        fill-opacity: 1;
        stroke-width: .5;
        stroke-dashoffset: 54px;
        transition: fill $t_slide,
        fill-opacity $t_slide,
        stroke-width $t_slide,
        stroke-dashoffset $t_slide .8s;
      }

      &_close {
        z-index: 2;
        path {
          fill: $c_gray_dark;
          fill-opacity: 1;
          stroke-width: 0;
          stroke-dashoffset: 0;
          transition: fill $t_slide 1.8s,
          fill-opacity $t_fade 1.8s,
          stroke-width $t_slide 1.8s,
          stroke-dashoffset $t_slide 1s;
        }
      }
    }

    &.header_gnav {
      opacity: 1;
      height: 100vh;
      overflow-y: scroll;
      transform: translateX(0);
      transition: transform $p_hum, opacity 1.5s;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .header_gnav_list {
        background: transparent;

        &_item {
          transform: translateX(0);
          opacity: 1;

          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              transition-delay: .4s + (.05s * $i);
            }
          }
        }
      }

      .header_gnav_top_list {
        background: transparent;

        &_item {
          transform: translateX(0);
          opacity: 1;

          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              transition-delay: .1s + (.02s * $i);
            }
          }
        }
      }

      .btn_round {
        transform: translateX(0);
        opacity: 1;
        transition-delay: .8s;
      }

      .btn {
        transform: translateX(0);
        opacity: 1;
        transition-delay: .85s;
      }

      .sns {
        transform: translateX(0);
        opacity: 1;
        transition-delay: .9s;
      }

      .header_gnav_bottom {
        transform: translateX(0);
        opacity: 1;
        transition-delay: .95s;
      }

      .header_gnav_offer {
        transition-delay: .2s;
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.close {
  @include mq(sp) {
    .hum {
      &_bar {
        &_u,
        &_m,
        &_b {
          border-color: $c_white;
        }

        &_u {
          animation: Humberger_bar_z_rev $p_hum*2.2 $e_syu 0s forwards;
        }

        &_m {
          animation: Humberger_bar_x_rev $p_hum*2.2 $e_syu 0s forwards;
        }

        &_b {
          animation: Humberger_bar_y_rev $p_hum*2.2 $e_syu 0s forwards;
        }
      }

      &_bg {
        transform: translate(0%, 0%);
        animation: Humberger_bg_rev .6s $e_curtain .2s forwards;

        &_sakura {
          transform: translate(0%, 100%);
          .sakura_sp {
            transform: translate(0%, 0%);
            animation: Humberger_bg_sakura_rev 1s $e_fade .4s forwards;
          }
        }
      }
    }
  }
}

.is-fixed {
  @include mq(sp) {
    height: 100%;
    overflow: hidden;
  }
}