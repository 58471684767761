/*
 * kv.scss
 *
 * キービジュアル用コンポーネント
 * 主にトップページで利用。
 */

.kv {
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  background: $c_bg_beppin-sakura;
  height: 100vh;
  box-shadow: 0 120px 80px $c_bg_beppin-sakura;
  @include responsive((
    min-height: 2160,
    margin-bottom: 480,
  ));
  @include mq(pc) {
    box-shadow: mycalc(0 240 160) $c_bg_beppin-sakura;
  }
  @include mq(sp) {
    min-height: mycalcSP(1408);
    margin-bottom: mycalcSP(424);
    box-shadow: mycalc(0 120 80) $c_bg_beppin-sakura;
  }

  &_headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 2;
    @include responsive((
      width: 1164,
      height: 400,
      margin-right: -1392,
      margin-top: -400,
    ));
    @media screen and (max-width: 1450px) {
      right: 0;
      margin-right: mycalc(80);
    }
    @include mq(sp) {
      flex-flow: column;
      width: 100%;
      height: auto;
      margin-right: 0;
      right: auto;
      left: 0;
      top: mycalcSP(192);
      margin-top: 0;
    }

    &_copy {
      order: -1;
      letter-spacing: .05em;
      @include responsive((
        font-size: 48,
        line-height: 64,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
        margin-bottom: mycalcSP(16);
      }
    }

    &_ttl {
      letter-spacing: 0;
      font-weight: 400;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      @include responsive((
        font-size: 144,
        line-height: 192,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        font-size: mycalcSP(96);
        line-height: mycalcSP(112);
        margin-bottom: mycalcSP(32);
      }
    }

    &_desc {
      letter-spacing: .15em;
      white-space: nowrap;
      @include responsive((
        font-size: 32,
        line-height: 64,
      ));
      @include mq(sp) {
        letter-spacing: .1em;
        white-space: normal;
        font-size: mycalcSP(28);
        line-height: mycalcSP(48);
      }
    }

    &_contents {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      @include responsive((
        width: 732,
        height: 400,
        margin-left: 32,
      ));
      @include mq(sp) {
        width: 100%;
        height: auto;
        margin-left: 0;
        align-items: center;
      }
    }

    &_mark {
      order: -1;
      @include responsive((
        width: 400,
        height: 400,
      ));
      @include mq(sp) {
        width: mycalcSP(192);
        height: mycalcSP(192);
        margin-bottom: mycalcSP(32);
      }

      .ico_logo_mark {
        @include responsive((
          width: 400,
          height: 400,
        ));
        @include mq(sp) {
          width: mycalcSP(192);
          height: mycalcSP(192);
        }

        path {
          fill: $c_pink_light;
        }
      }
    }

  }

  &_figure {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    //top: 0;
    left: 50%;
    z-index: 0;
    top: (21.2169px);
    @include responsive((
      width: 1584,
      height: 2112,
      margin-left: -792,
    ));
    @include mq(pc) {
      //transform: translateY(#{mycalc(42.4228)});
      top: mycalc(42.4228);
    }
    @include mq(sp) {
      width: mycalcSP(750);
      height: mycalcSP(1000);
      left: 0;
      margin-left: 0;
      //top: mycalcSP(288);
      //transform: translateY(#{mycalcSP(43.9232)});
      top: mycalcSP(288+43.9232);
    }

    &_img {
      max-width: none;
      @include responsive((
        width: 3160,
        height: 2112,
      ));
      @include mq(sp) {
        width: mycalcSP(750);
        height: mycalcSP(1000);
      }
    }
  }

  &_figure_sub {
    &_item {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      @include responsive((
        width: 564,
        height: 640,
      ));
      @include mq(sp) {
        width: mycalcSP(240);
        height: mycalcSP(272);
        overflow: visible;
      }

      &:nth-child(1) {
        bottom: 50%;
        left: 50%;
        @include responsive((
          margin-bottom: -1160,
          margin-left: -1200,
        ));
        @include mq(sp) {
          bottom: auto;
          top: 50%;
          margin-bottom: 0;
          margin-top: mycalcSP(-684);
          margin-left: mycalcSP(136);
          transform: translateY(#{mycalcSP(235.576)});
        }

        .vs-pax {
          @include responsive((
            padding-bottom: 114.8656
          ));
        }
      }

      &:nth-child(2) {
        top: 50%;
        left: 50%;
        @include responsive((
          height: 564,
          margin-top: -580,
          margin-left: -1724,
        ));
        @include mq(sp) {
          height: mycalcSP(240);
          margin-top: 0;
          top: auto;
          bottom: 50%;
          margin-left: mycalcSP(-336);
          margin-bottom: mycalcSP(-596);
          transform: translateY(#{mycalcSP(-33.0634)});
        }

        .kv_figure_sub_img {
          @include responsive((
            height: 840,
          ));
          @include mq(sp) {
            height: mycalcSP(240);
          }
        }

        .vs-pax {
          @include responsive((
            padding-top: 268.0652
          ));
        }
      }

      &:nth-child(3) {
        top: 50%;
        right: 50%;
        @include responsive((
          margin-top: -20,
          margin-right: -2028,
        ));
        @include mq(sp) {
          display: none;
        }

        .vs-pax {
          @include responsive((
            padding-top: 104.6088
          ));
        }
      }
    }

    &_img {
      max-width: none;
      @include responsive((
        width: 840,
        height: 960,
      ));
      @include mq(sp) {
        width: mycalcSP(240);
        height: mycalcSP(272);
      }
    }
  }

  &_news {
    position: absolute;
    background: rgba(253, 248, 249, .7);
    right: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 3;
    @include responsive((
      width: 1488,
      margin-right: -1920,
      bottom: 112,
    ));
    @include mq(full) {
      margin-right: 0;
      right: 0;
    }
    @include mq(wide) {
      margin-right: 0;
      right: 0;
    }
    @include mq(sp) {
      width: mycalcSP(668);
      right: 0;
      margin-right: 0;
      bottom: mycalcSP(-32 + 79.0288);
    }

    .archive_list_item {
      margin-bottom: 0;
      border-bottom: 0;
      border-right: 1px solid $c_white;
      box-sizing: border-box;
      @include responsive((
        width: 1232,
        padding: 60 112 60 80,
      ));
      @include mq(sp) {
        width: mycalcSP(524);
        padding: mycalcSP(48 32 48 48);
      }

      &_link {
      }

      .meta {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-flow: column;
        @include responsive((
          width: 160,
          margin-right: 76,
        ));
        @include mq(sp) {
          width: 100%;
          margin-right: 0;
          flex-flow: row;
          margin-bottom: mycalcSP(16);
        }
      }

      .time {
        width: 100%;
        display: flex;
        margin: 0;
        @include mq(sp) {
          width: auto;
          margin-right: mycalcSP(32);
        }
      }

      .tag {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        margin: 0;
        @include mq(sp) {
          width: auto;
        }
      }

      .title {
        @include responsive((
          width: 800,
          max-height: 88,
          font-size: 28,
          line-height: 44,
          margin-top: 50,
        ));
        @include mq(sp) {
          width: 100%;
          max-height: none;
          font-size: mycalcSP(28);
          line-height: mycalcSP(44);
          letter-spacing: .05em;
          margin-top: 0;
        }
      }
    }

    &_link {
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive((
        width: 256,
      ));
      @include mq(sp) {
        width: mycalcSP(144);
      }

      &_box {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .ico_arw {
          @include mq(sp) {
            width: mycalcSP(48);
            height: mycalcSP(48);
          }
        }
      }

      &_txt {
        @include responsive((
          font-size: 24,
          line-height: 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(32);
        }
      }
    }
  }

  &_bg {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    @include responsive((
      width: 4138,
      height: 3360,
      margin-top: -1880,
      margin-left: -2136,
    ));
    @include mq(sp) {
      width: mycalcSP(750);
      height: mycalcSP(1334);
      margin-top: 0;
      top: 0;
      left: 0;
      margin-left: 0;
    }

    .sakura_pc {
      position: absolute;
      top: 0;
      left: 0;
      @include responsive((
        width: 4138,
        height: 3360,
      ));
      @include minq(sp) {
        display: block;
      }
      @include mq(sp) {
        display: none;
      }
    }

    .sakura_sp {
      @include minq(sp) {
        display: none;
      }
      @include mq(sp) {
        position: absolute;
        display: block;
        width: mycalcSP(1004);
        height: mycalcSP(1422);
        top: 50%;
        left: 50%;
        margin-left: mycalcSP(-492);
        margin-top: mycalcSP(-720);
      }
    }
  }

}
