/*
 * footer.scss
 *
 * フッター用コンポーネント
 */

.footer {
  width: 100%;
  color: $c_text;
  position: relative;
  @include responsive((
    padding: 320 0 316,
  ));
  @include mq(sp) {
    padding: mycalcSP(128 0 160);
  }

  &_inner {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    @include responsive((
      width: 2560,
      padding: 0 80,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(0 72);
      flex-flow: column;
    }
  }

  &_logo {
    box-sizing: border-box;
    @include responsive((
      width: 330,
    ));
    @include mq(sp) {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: mycalcSP(80);
    }

    &_link {
      text-align: center;
      display: block;
      @include responsive((
        width: 330,
      ));
      @include mq(sp) {
        width: mycalcSP(248);
      }

      img, svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &_contents {
    box-sizing: border-box;
    @include responsive((
      width: 1984,
    ));
    @include mq(sp) {
      width: 100%;
    }
  }

  &_top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid $c_white;
    position: relative;
    @include responsive((
      padding-bottom: 160,
      margin-bottom: 128,
    ));
    @include mq(sp) {
      flex-flow: column;
      padding-bottom: mycalcSP(152);
      margin-bottom: mycalcSP(96);
    }
  }

  &_bottom {
    width: 100%;

    &_list {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include responsive((
        margin-bottom: 80,
      ));
      @include mq(sp) {
        width: 100%;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-bottom: mycalcSP(96);
      }

      &_item {
        @include responsive((
          font-size: 28,
          line-height: 40,
          margin-left: 64,
        ));
        @include mq(sp) {
          font-size: mycalcSP(26);
          line-height: mycalcSP(40);
          margin-left: 0;
          margin-bottom: mycalcSP(48);

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          letter-spacing: .1em;
          color: $c_text;
          border-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .ico_arw {
            transform: rotate(-45deg);
            @include responsive((
              width: 40,
              height: 40,
              margin-left: 4,
            ));
            @include mq(sp) {
              width: mycalcSP(40);
              height: mycalcSP(40);
              margin-left: mycalcSP(4);
            }
          }
        }
      }
    }

    .copyright {
      width: 100%;
      font-family: $f_en;
      letter-spacing: .1em;
      text-align: right;
      @include responsive((
        font-size: 28,
        line-height: 40,
      ));
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(24);
        line-height: mycalcSP(32);
        text-align: center;
      }
    }
  }

  &_nav {
    box-sizing: border-box;
    position: relative;
    @include responsive((
      width: 480,
      margin-right: 132,
    ));
    @include mq(sp) {
      width: 100%;
      margin-bottom: mycalcSP(84);
      margin-right: 0;
    }

    &_list {
      width: 100%;

      &_item {
        @include responsive((
          margin-bottom: 64,
        ));
        @include mq(sp) {
          width: 100%;
          margin-bottom: mycalcSP(40);
        }

        &:last-child {
          margin-bottom: 0;

          @include mq(sp) {
            margin-bottom: 0;
          }
        }

        &_txt {
          letter-spacing: .05em;
          font-weight: inherit;
          @include responsive((
            font-size: 32,
            line-height: 48,
          ));
          @include mq(sp) {
            font-size: mycalcSP(28);
            line-height: mycalcSP(36);
          }
        }
      }
    }

    .ico_arw {
      @include responsive((
        width: 32,
        height: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
        margin-right: mycalcSP(8);
      }
    }
  }

  &_touchpoint {
    @include responsive((
      width: 564,
      margin-right: 216,
    ));
    @include mq(sp) {
      width: 100%;
      margin-right: 0;
    }

    &_address {
      letter-spacing: .05em;
      @include responsive((
        font-size: 28,
        line-height: 64,
        margin-bottom: 80,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(64);
      }
    }

    .btn {
      @include responsive((
        margin-bottom: 128,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(128);
      }
    }

    &_sns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &_pagetop {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    border: solid $c_border;
    overflow: hidden;
    @include responsive((
      width: 192,
      height: 192,
      border-width: 2,
    ));
    @include mq(sp) {
      width: mycalcSP(96);
      height: mycalcSP(96);
      border-width: mycalcSP(2);
      position: absolute;
      right: mycalcSP(16);
      bottom: mycalcSP(96);
    }

    .ico_arw {
      position: relative;
      z-index: 1;
      transform: rotate(-90deg);
      @include responsive((
        width: 64,
        height: 64,
      ));
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
      }
    }
  }

  &_offer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
    background: $c_bg_cta_contact;

    &_sec {
      text-align: center;
      box-sizing: border-box;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      @include responsiveFull((
        width: 2148,
        padding: 128 84 160,
      ));
      @include mq(full) {
        width: mycalcFull(2148);
        padding: mycalcFull(128 84 160);
      }
      @include mq(wide) {
        width: mycalcWide(1884);
        padding: mycalcWide(120 120 160);
      }
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(80 40 96);
      }

      .ttl {
        @include responsive((
          margin-top: 248,
          margin-bottom: 232,
        ));
        @include mq(full) {
          margin-top: mycalcFull(248);
          margin-bottom: mycalcFull(232);
        }
        @include mq(wide) {
          padding: mycalcWide(370 0 350);
          margin-top: mycalcWide(0);
          margin-bottom: mycalcWide(0);
        }
        @include mq(sp) {
          padding: mycalcSP(64 0 56);
          margin-top: mycalcSP(104);
          margin-bottom: mycalcSP(80);
        }

        &_ja {
          @include mq(wide) {
            font-size: mycalcWide(60);
            line-height: mycalcWide(80);
          }
          @include mq(sp) {
            letter-spacing: .05em;
            font-size: mycalcSP(40);
            line-height: mycalcSP(64);
          }
        }

        &_en {
          top: 50%;
          left: 50%;
          text-indent: 0;
          @include responsive((
            margin-top: -480,
            margin-left: -980,
          ));
          @include mq(full) {
            margin-top: mycalcFull(-480);
            margin-left: mycalcFull(-980);
            font-size: mycalcFull(480);
            line-height: mycalcFull(480);
          }
          @include mq(wide) {
            margin-top: mycalcWide(-400);
            margin-left: mycalcFull(-980);
            font-size: mycalcWide(400);
            line-height: mycalcWide(400);
          }
          @include mq(sp) {
            letter-spacing: .1em;
            margin-top: mycalcSP(-176);
            font-size: mycalcSP(176);
            line-height: mycalcSP(176);
            margin-left: mycalcSP(-364);
          }
        }
      }

      &_desc {
        font-weight: bold;
        position: relative;
        @include responsive((
          font-size: 32,
          line-height: 48,
          margin-bottom: 96
        ));
        @include mq(wide) {
          font-size: mycalcWide(40);
          line-height: mycalcWide(60);
          margin-bottom: mycalcWide(80);
        }
        @include mq(sp) {
          letter-spacing: .05em;
          font-size: mycalcSP(32);
          line-height: mycalcSP(48);
          margin-bottom: mycalcSP(48);
        }
      }

      .btn {
        @include responsive((
          width: 720,
        ));
        @include mq(sp) {
          width: 100%;
        }
      }
    }

    &_figure {
      overflow: hidden;
      order: -1;
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive((
        width: 1692,
        height: 1568,
      ));
      @include mq(full) {
        width: mycalcFull(1692);
      }
      @include mq(wide) {
        width: mycalcWide(1316);
        height: mycalcWide(1440);
      }
      @include mq(sp) {
        width: mycalcSP(750);
        height: mycalcSP(640);
      }

      &_img {
        @include responsive((
          width: 1692,
          height: 1768,
        ));
        @include mq(full) {
          max-width: none;
          width: 100%;
          height: auto;
        }
        @include mq(wide) {
          max-width: none;
          width: mycalcWide(1316);
          height: mycalcWide(1700);
        }
        @include mq(sp) {
          width: mycalcSP(750);
          height: mycalcSP(800);
        }
      }
    }
  }


}