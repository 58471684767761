.product {
  &_archive {
    @include responsive((
      margin-bottom: 148,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding: mycalcSP(0 72);
      margin-bottom: mycalcSP(128);
    }

    .ttl {
      align-items: flex-start;
      @include responsive((
        padding: 240 0 104,
      ));
      @include mq(sp) {
        padding: mycalcSP(192 0 28);
      }

      &_ja {
        @include responsive((
          margin-top: 40,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(20);
        }
      }

      &_en {
        color: $c_gray;
        letter-spacing: 0;
        position: relative;
        top: 0;
        @include responsive((
          font-size: 320,
          line-height: 320,
          left: -48,
        ));
        @include mq(sp) {
          font-size: mycalcSP(96);
          line-height: mycalcSP(96);
          left: 0;
        }

      }
    }

    &_list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .box_card {
        @include responsive((
          width: 720,
          margin-right: 120,
          margin-bottom: 76,
        ));
        @include mq(sp) {
          width: 100%;
          margin-right: 0;
          margin-bottom: mycalcSP(64);
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(3n-1) {
          @include responsive((
            margin-top: 192,
          ));
          @include mq(sp) {
            margin-top: 0;
          }
        }

        &:last-child {
          @include mq(sp) {
            margin-bottom: 0;
          }
        }

        &_link {
          width: 100%;
        }

        &_thumb {
          position: relative;
          @include responsive((
            width: 720,
            height: 960,
            margin-bottom: 36,
          ));
          @include mq(sp) {
            width: mycalcSP(606);
            height: mycalcSP(808);
            margin-bottom: mycalcSP(32);
          }

          &_img {
            max-width: none;
            position: absolute;
            @include responsive((
              width: 864,
              height: 1152,
              top: -96,
              left: -72,
            ));
            @include mq(sp) {
              width: mycalcSP(728);
              height: mycalcSP(970.6);
              top: mycalcSP(-90.7);
              left: mycalcSP(-61);
            }
          }
        }

        &_desc {
          order: 0;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start;

          &_ttl {
            @include responsive((
              font-size: 44,
              line-height: 64,
            ));
            @include mq(sp) {
              font-size: mycalcSP(40);
              line-height: mycalcSP(48);
            }
          }
        }
      }
    }
  }

  &_series {
    width: 100%;

    &_desc {
      letter-spacing: .1em;
      box-sizing: border-box;
      @include responsive((
        width: 2560,
        padding: 0 80,
        font-size: 32,
        line-height: 80,
        margin: 0 auto 240,
      ));
      @include mq(sp) {
        letter-spacing: .05em;
        width: mycalcSP(750);
        padding: mycalcSP(0 72);
        font-size: mycalcSP(30);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(128);
      }
    }

    &_sec {
      @include responsive((
        padding-bottom: 8
      ));
      @include mq(sp) {
        padding-bottom: mycalcSP(8);
      }
    }

    &_list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: wrap;
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        padding: mycalcSP(0 72);
      }

      &_term {
        width: 100%;
        letter-spacing: .1em;
        @include responsive((
          font-size: 48,
          line-height: 64,
          margin-bottom: 128,
        ));
        @include mq(sp) {
          box-sizing: border-box;
          padding: mycalcSP(0 72);
          font-size: mycalcSP(40);
          line-height: mycalcSP(64);
          margin-bottom: mycalcSP(64);
        }
      }

      .box_card {
        margin-bottom: 0;
        @include responsive((
          width: 736,
          margin-right: 96,
          margin-top: 208,
        ));
        @include mq(sp) {
          width: mycalcSP(288);
          margin-right: mycalcSP(30);
          margin-top: mycalcSP(96);

          &:nth-child(-n+2) {
            margin-top: 0;
          }

          &:nth-child(even) {
            margin-right: 0;
          }
        }

        @include minq(sp) {
          &:nth-child(-n+3) {
            margin-top: 0;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        &_link {
          width: 100%;
        }

        &_data {
          width: 100%;

          .product_name {
            width: 100%;
            letter-spacing: .05em;
            @include responsive((
              font-size: 36,
              line-height: 48,
              margin-bottom: 16,
            ));
            @include mq(sp) {
              font-size: mycalcSP(32);
              line-height: mycalcSP(40);
              margin-bottom: mycalcSP(8);
            }
          }

          .product_meta {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @include responsive((
              margin-bottom: 48
            ));
            @include mq(sp) {
              margin-bottom: mycalcSP(24);
            }
          }

          .product_id {
            letter-spacing: .05em;
            @include responsive((
              font-size: 28,
              line-height: 48,
            ));
            @include mq(sp) {
              width: 100%;
              font-size: mycalcSP(26);
              line-height: mycalcSP(32);
              margin-bottom: mycalcSP(16);
            }
          }

          .product_price {
            text-align: right;
            letter-spacing: .05em;
            @include responsive((
              font-size: 40,
              line-height: 48,
            ));
            @include mq(sp) {
              width: 100%;
              font-size: mycalcSP(32);
              line-height: mycalcSP(48);
              text-align: left;
            }
            small {
              letter-spacing: .05em;
              @include responsive((
                font-size: 28,
                line-height: 48,
                margin-left: 12,
              ));
              @include mq(sp) {
                font-size: mycalcSP(24);
                line-height: mycalcSP(48);
                margin-left: mycalcSP(12);
              }
            }
          }

          .product_cta {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mq(sp) {
              flex-wrap: wrap;
            }
          }

          .btn {
            margin: 0;
            @include responsive((
              width: 330,
              height: 88,
            ));
            @include mq(sp) {
              width: mycalcSP(254);
              height: mycalcSP(60);
            }

            &_box {
              width: 100%;
            }

            &_link {
              padding-right: 0;
              padding-left: 0;
              width: 100%;
              justify-content: space-between;

              &_txt {
                @include responsive((
                  font-size: 32,
                  line-height: 48,
                  margin-top: 4,
                ));
                @include mq(sp) {
                  font-size: mycalcSP(28);
                  line-height: mycalcSP(44);
                  margin-top: mycalcSP(4);
                }
              }

              .ico_arw {
                @include mq(sp) {
                  width: mycalcSP(32);
                  height: mycalcSP(32);
                }
              }
            }
          }

          .btn_round {
            margin: 0;
            @include responsive((
              width: 352,
              height: 112,
            ));
            @include mq(sp) {
              width: 100%;
              height: mycalcSP(100);
              margin-top: mycalcSP(32);
            }

            &_link {
              &_txt {
                @include responsive((
                  font-size: 32,
                  line-height: 48,
                ));
                @include mq(sp) {
                  font-size: mycalcSP(28);
                  line-height: mycalcSP(44);
                }
              }

              .ico_arw {
                position: relative;
                top: 0;
                right: 0;
                @include responsive((
                  width: 48,
                  height: 48,
                  margin-left: 16,
                ));
                @include mq(sp) {
                  width: mycalcSP(32);
                  height: mycalcSP(32);
                  margin-left: mycalcSP(24);
                }
              }

              .ico_ec {
                @include responsive((
                  margin-right: 16,
                  margin-left: 16,
                ));
                @include mq(sp) {
                  margin-right: mycalcSP(16);
                  margin-left: mycalcSP(16);
                }
              }
            }
          }
        }

        &_thumb {
          @include responsive((
            width: 736,
            height: 736,
            margin-bottom: 48,
          ));
          @include mq(sp) {
            width: mycalcSP(288);
            height: mycalcSP(288);
            margin-bottom: mycalcSP(32);
          }
        }
      }
    }
  }
}