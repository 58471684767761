/*
 * mv.scss
 *
 * メインビジュアル用コンポーネント
 * 主に下層ページで利用。
 */

.mv {
  width: 100%;
  z-index: 0;
  position: relative;
  @include responsive((
    padding-top: 240,
    margin-bottom: 80,
  ));
  @include mq(sp) {
    padding-top: mycalcSP(80);
    margin-bottom: mycalcSP(96);
  }

  &_headline {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include responsive((
      width: 2400,
      margin-bottom: 128,
    ));
    @include mq(sp) {
      width: mycalcSP(670);
      margin-bottom: mycalcSP(64);
    }

    &_en {
      order: 1;
      font-family: $f_en;
      color: $c_pink_dark;
      letter-spacing: 0;
      @include responsive((
        font-size: 192,
        line-height: 208,
        margin-bottom: 16,
      ));
      @include mq(sp) {
        font-size: mycalcSP(96);
        line-height: mycalcSP(96);
        margin-bottom: mycalcSP(48);
      }
    }

    &_ja {
      order: 2;
      letter-spacing: .1em;
      font-weight: bold;
      font-family: $f_en;
      @include responsive((
        font-size: 72,
        line-height: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(44);
        line-height: mycalcSP(72);
      }
    }

    &_logo {
      order: -1;
      @include responsive((
        margin-bottom: 48,
      ));
      @include mq(sp) {
        height: mycalcSP(40);
        margin-bottom: mycalcSP(48);

        img {
          display: block;
        }
      }
    }
  }

  &_contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include responsive((
      width: 2400,
      padding-left: 32,
      margin: 0 auto,
    ));
    @include mq(sp) {
      box-sizing: border-box;
      width: mycalcSP(750);
      padding: mycalcSP(0 64);
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &_desc {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1;
    @include responsive((
      width: 1280,
      padding: 0 32,
    ));
    @include mq(sp) {
      width: 100%;
      padding: 0;
    }

    &_copy {
      letter-spacing: .1em;
      font-weight: bold;
      font-family: $f_en;
      @include responsive((
        font-size: 64,
        line-height: 112,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(40);
        line-height: mycalcSP(72);
        margin-bottom: mycalcSP(64);
      }

      .sub_copy {
        font-weight: bold;
        display: block;
        @include responsive((
          margin-top: 32,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(32);
        }
      }

      .em, em, strong{
        border-bottom: dashed $c_pink;
        color: $c_pink;
        font-weight: inherit;
        @include responsive((
          border-bottom-width: 4,
          padding-bottom: 6,
        ));
        @include mq(sp) {
          border-bottom-width: mycalcSP(4);
          padding-bottom: mycalcSP(6);
        }

        .studio & {
          border-color: $c_brand;
          color: $c_brand;
          font-weight: inherit;
        }

        .academy & {
          border-color: $c_brand;
          color: $c_brand;
          font-weight: inherit;
        }
      }

      small {
        letter-spacing: .1em;
        font-weight: inherit;
        @include responsive((
          font-size: 32,
          line-height: 56,
        ));
        @include mq(sp) {
          font-size: mycalcSP(20);
          line-height: mycalcSP(36);
        }
      }
    }

    &_txt {
      @include responsive((
        font-size: 32,
        line-height: 72,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(64);
      }

      &:last-child {
        margin-bottom: 0;
      }

      small {
        @include responsive((
          font-size: 24,
          line-height: 72,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(64);
        }
      }
    }

    .btn, .btn_back {
      @include responsive((
        margin-top: 128,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(96);
      }
    }
  }

  &_figure {
    z-index: 0;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
    @include responsive((
      width: 1120,
      height: 1120,
    ));
    @include mq(sp) {
      width: mycalcSP(480);
      height: mycalcSP(480);
      margin-bottom: mycalcSP(80);
      order: -1;
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

}

.page_mv,
.product_series_mv {
  width: 100%;
  background: $c_bg_ar;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include responsive((
    height: 1920,
    margin-top: -32,
    margin-bottom: 240,
  ));
  @include mq(full) {
    height: mycalcFull(1920);
    margin-bottom: mycalcFull(240);
  }
  @include mq(wide) {
    height: mycalcWide(1600);
    margin-bottom: mycalcWide(200);
  }
  @include mq(sp) {
    height: auto;
    margin-top: mycalcSP(96);
    margin-bottom: mycalcSP(128);
  }

  .ttl {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0;
    color: $c_text;

    @include responsive((
      width: 1920,
      height: 1920,
      padding: 0 0 0 228,
    ));
    @include mq(full) {
      width: mycalcFull(1920);
      height: mycalcFull(1920);
      padding: mycalcFull(0 0 0 228);
    }
    @include mq(wide) {
      width: mycalcWide(1600);
      height: mycalcWide(1600);
      padding: mycalcWide(0 0 0 190);
    }
    @include mq(sp) {
      box-sizing: border-box;
      width: mycalcSP(750);
      height: auto;
      padding: mycalcSP(128 72);
    }

    &_en {
      color: inherit;
      letter-spacing: 0;
      position: relative;
      top: 0;
      text-indent: 0;
      @include responsive((
        font-size: 256,
        line-height: 256,
        margin-bottom: 72,
      ));
      @include mq(wide) {
        font-size: mycalcWide(214);
        line-height: mycalcWide(214);
        margin-bottom: mycalcWide(60);
      }
      @include mq(sp) {
        font-size: mycalcSP(80);
        line-height: mycalcSP(80);
        margin-bottom: mycalcSP(16);
      }
    }

    &_ja {
      color: inherit;
      letter-spacing: 0;
      font-weight: 400;
      font-family: $f_mincho;
      @include responsive((
        font-size: 64,
        line-height: 84,
        margin-left: 20,
      ));
      @include mq(wide) {
        font-size: mycalcWide(76);
        line-height: mycalcWide(100);
        margin-left: mycalcWide(20);
      }
      @include mq(sp) {
        letter-spacing: .1em;
        font-size: mycalcSP(40);
        line-height: mycalcSP(64);
        margin-left: mycalcSP(0);
      }
    }
  }


  &.light .ttl {
    color: $c_white;
  }

  &_figure {
    order: -1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive((
      width: 1920,
      height: 1920,
    ));
    @include mq(full) {
      width: mycalcFull(1920);
      height: mycalcFull(1920);
    }
    @include mq(wide) {
      width: mycalcWide(1600);
      height: mycalcWide(1600);
    }
    @include mq(sp) {
      width: mycalcSP(750);
      height: mycalcSP(750);
    }

    &_img {
      @include responsive((
        width: 1920,
        height: 2560,
      ));
      @include mq(full) {
        width: mycalcFull(1920);
        height: mycalcFull(2560);
      }
      @include mq(wide) {
        width: mycalcWide(1600);
        height: auto;
      }
      @include mq(sp) {
        width: mycalcSP(750);
      }

      .is-active & {
        clip-path: inset(0% 0% 0% 0%);
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .about & {
    background: #BDC3C7;
  }

  .story & {
    background: #F0EEEF;
  }

  .ar & {
    background: $c_bg_ar;
  }

  .mai-sakura & {
    background: $c_bg_mai-sakura;
  }

  .beppin-sakura & {
    background: $c_bg_beppin-sakura;
  }

  .sui & {
    background: $c_bg_sui;
  }

  .hana-sakura & {
    background: $c_bg_hana-sakura;
  }

  .hana-hoho & {
    background: $c_bg_hana-hoho;
  }

  .kesho-hana & {
    background: $c_bg_kesho-hana;
  }

  .rotunda & {
    background: $c_bg_rotunda;
  }

  .face-wash & {
    background: $c_bg_flower;
  }

  .gift & {
    background: $c_bg_gift;
  }

  .others & {
    background: $c_bg_others;
  }
}