.home {
  &.main {
    padding-top: 0;
  }
}

.top,
.page {

  &_about {
    .ttl {
      @include mq(sp) {
        margin-bottom: mycalcSP(92);
      }

      &_en {
        letter-spacing: 0;
        white-space: nowrap;
      }
    }

    &_inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @include responsive((
        padding-bottom: 332,
      ));

      @include mq(sp) {
        flex-wrap: wrap;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: mycalcSP(40);
      }
    }

    &_contents {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;

      @include responsive((
        width: 1176,
        padding: 0 252 0 0,
      ));
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        padding: mycalcSP(96 72);
      }
    }

    &_ttl {
      letter-spacing: .1em;
      white-space: nowrap;
      font-weight: 400;
      @include responsive((
        font-size: 80,
        line-height: 128,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        white-space: normal;
        letter-spacing: .05em;
        font-size: mycalcSP(48);
        line-height: mycalcSP(88);
        margin-bottom: mycalcSP(64);
        padding: mycalcSP(0);
      }
    }

    &_desc {
      @include mq(sp) {
        letter-spacing: .05em;
      }
    }

    .btn {
      align-self: flex-end;
    }

    &_figure {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive((
        width: 972,
        height: 1296,
        margin-top: 272,
      ));
      @include mq(sp) {
        order: -1;
        width: mycalcSP(606);
        height: mycalcSP(808);
        margin: 0 auto;
      }

      &_img {
        max-width: none;
        @include responsive((
          width: 1172,
          height: 1296,
        ));
        @include mq(sp) {
          width: mycalcSP(700);
          height: mycalcSP(808);
        }
      }
    }
  }

  &_product {
    position: relative;

    .ttl {
      align-items: flex-start;
      padding: 0;
      @include mq(sp) {
        margin-bottom: mycalcSP(120);
      }

      &_ja {
        @include responsive((
          padding: 0 0 0 204,
        ));
        @include mq(sp) {
          padding-left: mycalcSP(72);
        }
      }

      &_en {
        transform-origin: top left;
        transform: rotate(90deg);
        @include responsive((
          top: -560,
          left: 392,
        ));
        @include mq(sp) {
          letter-spacing: .1em;
          top: mycalcSP(-136);
          left: mycalcSP(188);
        }
      }
    }

    .swiper_product {
      overflow: visible;
      position: static;
      @include responsive((
        height: 1680,
        padding-left: 200,
      ));
      width: calc(100vw - ((100vw - 996px)*.5));

      @include mq(pc) {
        width: calc(100vw - ((100vw - #{mycalc(1992)})*.5));
      }
      @include mq(sp) {
        width: mycalcSP(606);
        height: mycalcSP(848);
        margin: 0 auto;
        padding-left: 0;
      }

      /*
      .archive_card {
        @include responsive((
          padding-left: 200,
        ));
        @include mq(sp) {
          padding-left: 0;
        }
      }
      */

      .swiper-scrollbar {
        position: relative;
        width: calc(100vw - ((100vw - 996px)*.5));
        @include responsive((
          height: 10,
          //margin-left: 204,
        ));
        @include mq(pc) {
          width: calc(100vw - ((100vw - #{mycalc(1992)})*.5));
        }
        @include mq(sp) {
          width: calc(100vw - #{mycalcSP(72)});
          height: mycalcSP(10);
          margin-left: 0;
          margin-top: mycalcSP(-2);
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          background: $c_white;
          @include responsive((
            height: 2,
            top: 5,
          ));
          @include mq(sp) {
            height: mycalcSP(2);
            top: mycalc(5);
          }
        }

        &-drag {
          cursor: pointer;
          position: relative;
          @include responsive((
            height: 10,
          ));
          @include mq(sp) {
            height: mycalcSP(10);
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            background: $c_brand;
            @include responsive((
              height: 2,
              top: 5,
            ));
            @include mq(sp) {
              height: mycalcSP(2);
              top: mycalc(5);
            }
          }
        }
      }

      .arw_next,
      .arw_prev {
        @include responsive((
          top: -96
        ));
        @include mq(sp) {
          top: mycalcSP(26);
        }
      }
    }

    .box_card {
      margin-bottom: 0;
      @include responsive((
        width: 960,
        height: 1472,
        margin-right: 96,
      ));
      @include mq(sp) {
        width: mycalcSP(528);
        height: mycalcSP(848);
        margin-right: mycalcSP(48);
      }

      &_link {
        @include responsive((
          width: 960,
          height: 1472,
        ));
        @include mq(sp) {
          width: mycalcSP(528);
          height: mycalcSP(848);
        }
      }

      &_desc {
        margin-bottom: 0;
        @include responsive((
          height: 144,
        ));
        @include mq(sp) {
          height: mycalcSP(64);
        }
        &_ttl {
          @include responsive((
            font-size: 48,
            line-height: 72,
          ));
          @include mq(sp) {
            letter-spacing: 0;
            font-size: mycalcSP(40);
            line-height: mycalcSP(64);
          }
        }
      }

      &_thumb {
        position: relative;
        //overflow: visible;
        @include responsive((
          width: 960,
          height: 1280,
        ));
        @include mq(sp) {
          width: mycalcSP(528);
          height: mycalcSP(704);
          margin-bottom: mycalcSP(20);
        }
        @include browser(ie) {
          overflow: hidden;
        }

        &_img {
          position: absolute;
          max-width: none;
          will-change: transform;
          transform: translate3d(-96px, 0, 0);
          transform-origin: left center;
          left: 0;
          @include responsive((
            width: 1152,
            height: 1536,
            top: -128,
            //left: -96,
          ));
          @include mq(pc) {
            transform: translate3d(#{mycalc(-192)}, 0, 0);
            transform-origin: left center;
          }
          @include mq(sp) {
            width: mycalcSP(636.6);
            height: mycalcSP(844.8);
            top: mycalcSP(-54.3);
            transform: translate3d(#{mycalcSP(-108.6)}, 0, 0);
            transform-origin: left center;
          }
        }
      }

      &:nth-child(even) {
        transform: translateY(80px);
        @include mq(pc) {
          transform: translateY(#{mycalc(160)});
        }
        @include mq(sp) {
          transform: translateY(0);
        }
      }
    }

    .btn {
      justify-content: flex-end;
      @include responsive((
        margin-top: 88
      ));
      @include mq(sp) {
        margin-top: mycalcSP(64);
        margin-right: mycalcSP(72);
      }

    }
  }

  &_story {
    width: 100%;
    @include responsive((
      margin-bottom: 320,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(160);
    }

    &_inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $c_pink_light;

      @include mq(sp) {
        flex-wrap: wrap;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &_contents {
      order: 2;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      @include responsive((
        width: 1176,
        padding: 0 720 0 252,
      ));
      @include mq(full) {
        width: mycalcFull(1176);
        padding: mycalcFull(0 720 0 252);
      }
      @include mq(wide) {
        width: mycalcWide(1215);
        padding: mycalcWide(0 355 0 315);
      }
      @include mq(sp) {
        width: 100%;
        box-sizing: border-box;
        padding: mycalcSP(96 72);
      }
    }

    &_ttl {
      letter-spacing: .1em;
      font-weight: 400;
      @include responsive((
        font-size: 80,
        line-height: 128,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        letter-spacing: .05em;
        font-size: mycalcSP(48);
        line-height: mycalcSP(88);
        margin-bottom: mycalcSP(64);
        padding: mycalcSP(0);
      }

      &_en {
        letter-spacing: 0;
        text-indent: 0;
      }
    }

    &_desc {

    }

    .btn {
      align-self: flex-end;
    }

    &_figure {
      order: 1;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive((
        width: 1692,
        height: 1288,
      ));
      @include mq(full) {
        width: mycalcFull(1692);
        height: mycalcFull(1288);
      }
      @include mq(wide) {
        width: mycalcWide(1315);
        height: mycalcWide(1490);
      }
      @include mq(sp) {
        width: mycalcSP(750);
        height: mycalcSP(570);
      }

      &_img {
        max-width: none;
        @include responsive((
          width: 1920,
          height: 1288,
        ));
        @include mq(full) {
          width: mycalcFull(1920);
          height: mycalcFull(1288);
        }
        @include mq(sp) {
          width: mycalcSP(848);
          height: mycalcSP(570);
        }
      }
    }

  }

  &_shop {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    @include responsive((
      padding: 144 284 0
    ));
    @include mq(sp) {
      padding: mycalcSP(128 72 0);
    }

    &_figure {
      order: 1;
      overflow: hidden;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive((
        width: 1176,
        height: 1176,
        margin-bottom: 132,
      ));
      @include mq(sp) {
        width: mycalcSP(606);
        height: mycalcSP(606);
        margin-bottom: mycalcSP(100);
      }

      &_img {
        max-width: none;
        @include responsive((
          width: 1376,
          height: 1176,
        ));
        @include mq(sp) {
          width: mycalcSP(840);
          height: mycalcSP(606);
        }
      }
    }

    .ttl {
      order: 2;
      padding: 0;
      align-items: flex-start;
      z-index: 1;
      @include responsive((
        margin-bottom: 96,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(48);
      }

      &_en {
        transform-origin: top left;
        transform: rotate(90deg);
        letter-spacing: .1em;
        @include responsive((
          top: -1500,
          right: -1712,
        ));
        @include mq(sp) {
          top: mycalcSP(-836);
          right: mycalcSP(-756);
        }
      }
    }

    &_desc {
      order: 3;
    }

    .btn {
      order: 4;
      @include responsive((
        margin-left: 640
      ));
      @include mq(sp) {
        margin-left: 0;
        align-self: flex-end;
      }
    }
  }

  &_column {
    position: relative;

    .ttl {
      align-items: flex-start;
      @include mq(sp) {
        margin-bottom: mycalcSP(168);
      }

      &_en {
        left: 50%;
        @include responsive((
          margin-left: -1980
        ));
        @include mq(sp) {
          left: 0;
          margin-left: 0;
        }
      }

      &_ja {
        @include mq(sp) {
          padding-left: mycalcSP(72);
        }
      }
    }

    .swiper {
      overflow: visible;
      position: static;
      @include responsive((
        height: 1000,
        padding-left: 48,
      ));
      width: calc(100vw - ((100vw - 1152px)*.5));

      @include mq(pc) {
        width: calc(100vw - ((100vw - #{mycalc(2304)})*.5));
      }
      @include mq(sp) {
        width: mycalcSP(606);
        height: mycalcSP(784);
        margin: 0 auto;
        padding-left: 0;
      }

      &-scrollbar {
        position: relative;
        width: calc(100vw - ((100vw - 1152px)*.5));
        @include responsive((
          height: 10,
          //margin-left: 48,
        ));
        @include mq(pc) {
          width: calc(100vw - ((100vw - #{mycalc(2304)})*.5));
        }
        @include mq(sp) {
          width: calc(100vw - #{mycalcSP(72)});
          height: mycalcSP(10);
          margin-left: 0;
          margin-top: mycalcSP(-2);
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          background: $c_white;
          @include responsive((
            height: 2,
            top: 5,
          ));
          @include mq(sp) {
            height: mycalcSP(2);
            top: mycalc(5);
          }
        }

        &-drag {
          cursor: pointer;
          position: relative;
          @include responsive((
            height: 10,
          ));
          @include mq(sp) {
            height: mycalcSP(10);
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            background: $c_brand;
            @include responsive((
              height: 2,
              top: 5,
            ));
            @include mq(sp) {
              height: mycalcSP(2);
              top: mycalc(5);
            }
          }
        }
      }
    }

    .box_card {
      margin-bottom: 0;
      filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.05));
      transition: filter $t_fade;
      @include responsive((
        margin-right: 144,
      ));
      @include mq(pc) {
        filter: drop-shadow(#{mycalc(0 0 48)} rgba(0, 0, 0, 0.05));
      }
      @include mq(sp) {
        margin-right: mycalcSP(48);
        filter: drop-shadow(#{mycalc(0 0 48)} rgba(0, 0, 0, 0.05));
      }

      &:hover {
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.15));
        @include mq(pc) {
          filter: drop-shadow(#{mycalc(0 0 64)} rgba(0, 0, 0, 0.15));
        }
        @include mq(sp) {
          filter: drop-shadow(#{mycalc(0 0 64)} rgba(0, 0, 0, 0.15));
        }
      }
    }

    .btn {
      justify-content: flex-end;
      @include responsive((
        margin-top: 88
      ));
      @include mq(sp) {
        margin-top: mycalcSP(64);
        margin-right: mycalcSP(72);
      }

    }
  }

  &_news {
    .btn {
      justify-content: center;
    }
  }

}

.r_mark {
  vertical-align: sub;

  .kv_headline_ttl & {
    @include responsive((
      font-size: 64,
      line-height: 84,
      margin-left: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(48);
      line-height: mycalcSP(40);
      margin-left: mycalcSP(16);
    }
  }

  .ttl_ja & {
    letter-spacing: .1em;
    @include responsive((
      font-size: 32,
      line-height: 64,
    ));
    @include mq(sp) {
      letter-spacing: .05em;
      font-size: mycalcSP(36);
      line-height: mycalcSP(64);
    }
  }

  .story .ttl_ja &,
  .page_story_ttl & {
    letter-spacing: .1em;
    @include responsive((
      font-size: 56,
      line-height: 64,
      margin-left: 8,
    ));
    @include mq(sp) {
      letter-spacing: .05em;
      font-size: mycalcSP(36);
      line-height: mycalcSP(64);
      margin-left: 0;
    }
  }
}

.arw {
  &_prev,
  &_next {
    cursor: pointer;
    border: 1px solid $c_purple_dark;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity $t_fade;
    @include responsive((
      width: 192,
      height: 192,
    ));
    @include mq(sp) {
      width: mycalcSP(96);
      height: mycalcSP(96);
    }

    .ico_arw {
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
      }
    }
  }

  &_prev {
    .ico_arw {
      transform: rotate(180deg);
    }

    .swiper_product &,
    .swiper & {
      position: absolute;
      right: 50%;
      @include responsive((
        top: 144,
        margin-right: -960,
      ));
      @include mq(sp) {
        box-sizing: border-box;
        top: mycalcSP(200);
        margin-right: mycalcSP(-190);
      }
    }
  }

  &_next {
    .swiper_product &,
    .swiper & {
      position: absolute;
      right: 50%;
      @include responsive((
        top: 144,
        margin-right: -1200,
      ));
      @include mq(sp) {
        box-sizing: border-box;
        top: mycalcSP(200);
        margin-right: mycalcSP(-302);
      }
    }
  }

}

.swiper-button-disabled {
  opacity: 0;
}
