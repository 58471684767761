/* =============================
 * Size
 */

$s_widthCompPC: 3840; // PC DesignCompData WidthSizePX
$s_widthCompSP: 750;  // SP DesignCompData WidthSizePX
$s_widthDesignMag: 2; // デザインデータの倍率（2倍の場合は、「2」）
$s_widthBase: 1280;   // デザインデータのPC基準値（最小固定値）
$s_widthWide: 1600;   // 推奨最小閲覧サイズ（PCのサイドメニューなども考慮すること）


/* =============================
 * Color
 */

$c_black: #000000;
$c_white: #ffffff;

$c_pink: #C40080;
$c_pink_opacity: rgba(223, 52, 129, 0.4);
$c_pink_opacity_12: #c4008012;
$c_pink_dark: #968490;
$c_pink_light: #FDF8F9;

$c_purple_dark: $c_pink_dark;

$c_gray: #CAC1C7;
$c_gray_dark: #4B3A45;
$c_gray_light: #FAF9FA;


/*
$c_gradient_emerald: linear-gradient(to right, #39fad7 0%, #39fad7 50%, #44ea76 60%, #44ea76 100%);
$c_gradient_emerald_smooth: linear-gradient(to right, #39fad7 0%, #44ea76 100%);
$c_gradient_blue: linear-gradient(to right, #0061FC 0%, #0069FF 80%, #0069FF 100%);
*/

// role
$c_brand: $c_pink;
$c_placeholder: #BBBBBB;

$c_bg: $c_gray_light;
$c_bg_cta_contact: #F1E5E7;

// series
$c_bg_ar: #5F6167;
$c_bg_beppin-sakura: #F1E5E7;
$c_bg_hana-sakura: #E9E9C1;
$c_bg_flower: #EFF7F7;
$c_bg_mai-sakura: #FBF4ED;

$c_bg_sui: #FBF4ED;
$c_bg_hana-hoho: #FBF4ED;
$c_bg_kesho-hana: #FBF4ED;
$c_bg_rotunda: #FBF4ED;
$c_bg_gift: #FBF4ED;
$c_bg_others: #FBF4ED;

$c_border: $c_purple_dark;
$c_btn: $c_purple_dark;
$c_text: $c_gray_dark;


/* =============================
 * Font
 */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@400;500;600&family=Shippori+Mincho:wght@400;500&display=swap');


$f_gothic: "游ゴシック体", "Yu Gothic", "游ゴシック", YuGothic, Verdana, "メイリオ", Meiryo, "M+ 1p", sans-serif;
$f_mincho: 'Shippori Mincho', "Yu Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN", serif, "Sawarabi Mincho"; // Android 明朝体非対応対策
$f_en: 'Cormorant Infant', $f_mincho;
$f_ie10: Verdana, Meiryo, sans-serif;
//$f_en_sans: 'Montserrat', $f_gothic;


/* =============================
 * Speed
 */

$p_hum : .8s;
$p_hv  : .6s;
$p_slide  : .6s;
$p_scroll  : .4s;
$p_over: .4s;


/* =============================
 * Easing
 */
$e_curtain     : cubic-bezier(.25, .46, .45, .94);
$e_curtain_rev : cubic-bezier(.94, .45, .46, .25);
$e_syu         : cubic-bezier(.645, .045, .355, 1);
$e_humberger   : cubic-bezier(.55, 0, .1, 1);
$e_slot        : cubic-bezier(.32, .95, .6, 1);
$e_scroll        : cubic-bezier(.32, .95, .6, 1);
$e_fade        : cubic-bezier(.32, .95, .6, 1);
$e_draw        : cubic-bezier(1, .6, .32, .78);
//$e_slot_child  : cubic-bezier(1, 1, .6, .6);

$t_btn : $p_hv $e_curtain;
$t_hv : $p_hv $e_slot;
$t_slide : $p_hv $e_slot;
$t_scroll : $p_scroll $e_scroll;
$t_fade : $p_hv + .6s $e_slot;
$t_slide_child : $p_slide $e_slot;
$t_transition : .5s cubic-bezier(.32, .95, .6, 1);
$t_slideUp : .9s cubic-bezier(.32, .95, .6, 1.3);
$t_bubble : .4s cubic-bezier(.3, .41, .73, 1.03);
