/*
 * archive.scss
 *
 * 記事一覧用コンポーネント
 */


.archive {
  @include responsive((
    margin-bottom: 240,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(192);
  }

  &_list {
    width: 100%;
    box-sizing: border-box;
    @include mq(sp) {
      padding: mycalcSP(0 72);
    }

    &_item {
      box-sizing: border-box;
      border-bottom: 1px solid $c_white;
      @include responsive((
        padding: 0 48 96,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        padding: mycalcSP(0 0 64);
        margin-bottom: mycalcSP(64);
      }

      &_link {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: wrap;
      }

      .time {
        flex-shrink: 0;
        font-family: $f_en;
        font-weight: 500;
        letter-spacing: .1em;
        @include responsive((
          width: 240,
          font-size: 32,
          line-height: 48,
          margin-right: 120,
        ));
        @include mq(sp) {
          width: mycalcSP(174);
          font-size: mycalcSP(28);
          line-height: mycalcSP(32);
          margin-right: 0;
        }
      }

      .tag {
        font-family: $f_en;
        font-weight: 400;
        letter-spacing: .1em;
        @include responsive((
          width: 336,
          font-size: 32,
          line-height: 48,
          margin-right: 76,
        ));
        @include mq(sp) {
          width: auto;
          font-size: mycalcSP(28);
          line-height: mycalcSP(32);
          margin-right: 0;
        }
      }

      .title {
        @include responsive((
          font-size: 32,
          line-height: 48,
        ));
        @include mq(sp) {
          width: 100%;
          font-size: mycalcSP(28);
          line-height: mycalcSP(40);
          margin-top: mycalcSP(32);
          display: block;
        }
      }

      &_link:hover {
        .title {
          text-decoration: underline;
        }
      }
    }
  }

  &_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    //flex-wrap: wrap;


  }
}