/*
 * form.scss
 *
 * フォーム用コンポーネント
 */

$f_min-height: 56px;

@include mq(pc) {
  $f_min-height: mycalc(56*2);
}

.form {
  width: 100%;
  @include responsive((
    padding-bottom: 48,
  ));
  @include mq(sp) {
    padding-bottom: mycalcSP(48);
  }

  &_area {
    margin: 0 auto;
    background: $c_white;
    box-sizing: border-box;
    @include responsive((
      width : 2400,
      padding : 48 400 120,
    ));

    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(0 40 32);
    }
  }

  &_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $c_gray;
    box-sizing: border-box;
    flex-wrap: wrap;
    @include responsive((
      padding : 48 0
    ));
    @include mq(sp) {
      padding: mycalcSP((48, 0));
    }

    &_ttl {
      height: $f_min-height;
      letter-spacing: .05em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include responsive((
        width : 600,
        padding : 0 0,
      ));
      @include mq(sp) {
        width: 100%;
      }

      .form_list_box &,
      .confirmation & {
        height: auto;
        @include mq(sp) {
          margin-bottom: mycalcSP(36);
        }
      }

      &_txt {
        font-weight: bold;
        letter-spacing: .05em;
        display: block;

        @include responsive((
          width : 400,
          font-size: 32,
          line-height: 72,
        ));
        @include mq(sp) {
          width: auto;
          font-size: mycalcSP(30);
          line-height: mycalcSP(64);
          margin-right: mycalcSP(40)
        }
      }

      &_must {
        letter-spacing: .1em;
        line-height: 1;
        color: $c_white;
        background: $c_pink;
        text-align: center;
        font-weight: bold;

        @include responsive((
          padding : 10 20,
          font-size: 28,
          line-height: 40,
        ));
        @include mq(sp) {
          width: 3em;
          padding: mycalcSP((10, 20));
          font-size: mycalcSP(26);
          line-height: mycalcSP(30);
        }
      }
    }

    &_cnt {
      letter-spacing: .05em;
      @include responsive((
        width: 1000,
        font-size: 30,
        line-height: 68,
      ));

      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(28);
        line-height: mycalcSP(60);
      }

      &.short {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq(sp) {
          flex-wrap: wrap;
        }

        .short_list {
          @include responsive((
            margin-right: 40,
          ));
          @include mq(sp) {
            width: mycalcSP(280);
            margin-right: mycalcSP(40);
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &_ipt {
        font-size: inherit;
        border: 1px solid $c_border;
        box-sizing: border-box;
        background: $c_gray_light;
        letter-spacing: 0;
        font-family: $f_gothic;
        @include responsive((
          width : 1000,
          height : 112,
          font-size: 30,
          line-height: 68,
          padding : 10 36
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(90);
          padding: mycalcSP(10 36);
          font-size: mycalcSP(28);
          line-height: mycalcSP(60);
        }

        .short & {
          @include responsive((
            width : 480,
          ));
          @include mq(sp) {
            width: 100%;
            margin-bottom: mycalcSP(32);

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &_txtarea {
        border: 1px solid $c_border;
        background: $c_gray_light;
        box-sizing: border-box;
        letter-spacing: 0;
        font-family: $f_gothic;
        @include responsive((
          width : 1000,
          height : 400,
          padding : 10 36,
          font-size: 30,
          line-height: 68,
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(320);
          padding: mycalcSP(10 36);
          font-size: mycalcSP(28);
          line-height: mycalcSP(60);
        }
      }

      &_select {
        display: block;
        width: 400px;
        height: $f_min-height;
        @include responsive((
          width : 800
        ));
        @include mq(sp) {
          width: 100%;
        }

        .form_select {

          display: block;
          width: 100%;
          height: 100%;

          * {
            position: relative;
            margin: 0;
            padding: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-color: inherit;
          }

          ul {
            list-style: none;
            padding: 0;
          }

          input {
            color: inherit;
            font: inherit;
            margin: 0;
            background: transparent;
            outline: none;
            border: none;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: none;
          }

          li::before {
            display: none;
          }

          &_box {
            width: 100%;
            /*height: 100%;*/
            cursor: pointer;
            background: $c_gray_light;

            &_expand {
              width: 0;
              height: $f_min-height;
              position: absolute;
              top: 0;
              right: 0;

              &:checked {

                & + .form_select_box_close_label {
                  display: block;

                  & + .form_select_box_options {

                    .form_select_box_label {
                      height: $f_min-height;

                      &:hover {
                        background-color: $c_gray;
                      }
                    }

                    & + .form_select_box_expand_label {
                      //display: none;

                      &::after {
                        -webkit-transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);
                        transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);
                      }
                    }

                  }

                }
              }


              &_label {
                display: block;
                width: 100%;
                height: $f_min-height;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;

                &::after {
                  position: absolute;
                  top: 1.2em;
                  right: 0.5em;
                  width: 0;
                  height: 0;
                  padding: 0;
                  content: '';
                  pointer-events: none;
                  border-top: 6px solid $c_gray;
                  border-right: 6px solid transparent;
                  border-left: 6px solid transparent;
                  -webkit-transform: translate(-50%, -50%) rotate(0deg) scaleY(1.75);
                  transform: translate(-50%, -50%) rotate(0deg) scaleY(1.75);
                  -webkit-transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
                  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
                  z-index: 2;
                }

                &:hover::after {
                  opacity: 1;
                }
              }
            }

            &_close {
              display: none;

              &_label {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
              }
            }

            &_items {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              border: 1px solid $c_gray;
              padding-top: $f_min-height;
              z-index: 1;
            }

            &_input {
              display: none;

              &:checked + .form_select_box_label {
                height: $f_min-height;
                margin-top: -$f_min-height;
              }
            }

            &_label {
              -webkit-transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
              transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              height: 0;
              overflow: hidden;
              background-color: $c_white;
              cursor: pointer;
              @include responsive((
                padding : (0, 30)
              ));
            }

            &_placeholder {
              height: $f_min-height;
              vertical-align: middle;
              position: absolute;
              top: 0;
              left: 0;
              background-color: transparent;
              color: $c_gray;
            }
          }
        }
      }

      .vertical-item {
        display: block;

        @include mq(sp) {
          > label {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: mycalcSP(24);

            .mwform-radio-field-text {
              line-height: 1.6;
              letter-spacing: 0;
            }
          }
        }
      }
    }

    &_desc {
      @include responsive((
        font-size: 26,
        line-height: 48,
        padding : 16 0 40,
      ));
      @include mq(sp) {
        padding: mycalcSP(10 0 20);
        font-size: mycalcSP(26);
        line-height: mycalcSP(48);
      }

      .mw_wp_form_confirm & {
        display: none;
      }
    }
  }
}


.mwform-radio-field,
.mwform-checkbox-field {
  label {
    position: relative;
    @include responsive((
      padding-left : 40
    ));
    @include mq(sp) {
      padding-left: mycalcSP(40);
    }

    .mwform-radio-field-text {
      &::before,
      &::after {
        border-radius: 50%;
      }
    }

    .mwform-radio-field-text,
    .mwform-checkbox-field-text {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        box-sizing: border-box;
      }

      &::before {
        background: $c_gray_light;
        border: 1px solid $c_gray;
        left: 0;
        transition: border-color $t_hv;
        @include responsive((
          width : 40,
          height : 40,
          margin-top : -20
        ));
        @include mq(sp) {
          width: mycalcSP(40);
          height: mycalcSP(40);
          margin-top: mycalcSP(-20);
        }
      }

      &::after {
        transition: background $t_hv;
        @include responsive((
          width : 24,
          height : 24,
          margin-top : -12,
          left : 8
        ));
        @include mq(sp) {
          width: mycalcSP(24);
          height: mycalcSP(24);
          margin-top: mycalcSP(-12);
          left: mycalcSP(8);
        }
      }
    }
  }

  input:checked {
    + .mwform-radio-field-text::before,
    + .mwform-checkbox-field-text::before {
      border-color: $c_pink;
    }

    + .mwform-radio-field-text::after,
    + .mwform-checkbox-field-text::after {
      background: $c_pink;
    }
  }
}


input[type="submit"] {
  outline: 0;
  width: 100%;
  height: 100%;
  border: 0;
  letter-spacing: .1em;
  cursor: pointer;
  background: none;
  color: $c_text;
  font-weight: bold;
  opacity: 1;
  transition: background-color .4s;
  font-family: $f_mincho;
  @include responsive((
    font-size: 32,
    line-height: 64,
  ));
  @include mq(sp) {
    font-size: mycalcSP(28);
    line-height: mycalcSP(48);
  }

  &:disabled {
    cursor: no-drop;
    background: $c_gray_light;
    color: $c_pink_opacity;
    -webkit-text-fill-color: $c_pink_opacity;
    z-index: 1;
  }
}

.error {
  &_message {
    color: $c_pink;
    font-weight: bold;
    margin: 50px auto;
    @include responsive((
      margin : (100, auto)
    ));
  }

  &_field {
    .form_list_cnt_ipt,
    .form_list_cnt_txtarea,
    .form_list_cnt_select .form_select_box_items,
    .form_list_cnt_set_label::before,
    .mwform-radio-field label .mwform-radio-field-text::before,
    .mwform-radio-field label .mwform-checkbox-field-text::before,
    .mwform-checkbox-field label .mwform-radio-field-text::before,
    .mwform-checkbox-field label .mwform-checkbox-field-text::before {
      border: 2px solid $c_pink;
    }

    &_msg,
    .error,
    .mw_wp_form & .error {
      color: $c_pink;
      font-weight: bold;
      padding-top: .2em;
    }
  }
}


.more_area {
  @include responsive((
    margin-top: 148,
  ));
  @include mq(sp) {
    margin-top: mycalcSP(64);
    padding-bottom: mycalcSP(64);
  }

  .btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* TODO:.errorのスタイルを指定 */