@use "sass:math";

[data-scroll-section] {
  will-change: transform;
}

.clip_txt,
._l {
  will-change: clip-path, opacity, transform;
  clip-path: inset(0% 0% 100% 0%);
  opacity: 0;
  transition: clip-path .8s $e_slot .2s,
  opacity 2.6s cubic-bezier(.16, 1, .3, 1),
;

  &.is-active {
    clip-path: inset(0% 0% 0% 0%);
    opacity: 1;
  }
}

.clip_circle {
  img {
    will-change: clip-path, opacity, transform;
    transform: translateY(50px);
    clip-path: circle(0% at 50% 10%);
    opacity: 0;
    transition: clip-path .8s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  &.is-active {
    img {
      clip-path: circle(50% at 50% 50%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.clip_figure {
  img {
    will-change: clip-path, opacity, transform;
    transform: translateY(50px);
    clip-path: inset(100% 0% 0% 0%);
    opacity: 0;
    transition: clip-path 1.2s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  &.clip_down {
    img {
      clip-path: inset(0% 0% 100% 0%);
      transform: translateY(-50px);
    }
  }

  &.is-active {
    img {
      clip-path: inset(0% 0% 0% 0%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.vs-pax {
  will-change: transform;
  transition: none;
}

.nowrap {
  white-space: nowrap;
}

.js-txt-up {
  opacity: 0;

  .is-active &,
  &.is-active {
    opacity: 1;
  }
}

._txt {
  will-change: transform, opacity;
  display: inline-block;
  opacity: 0;
  transform: translateY(80%);
  transition: opacity 1s cubic-bezier(.19, 1, .22, 1),
  transform 1s cubic-bezier(.19, 1, .22, 1);

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      transition-delay: .03s * $i;
    }
  }

  .is-active & {
    opacity: 1;
    transform: translateY(0);
  }
}


.kv_figure {
  img {
    will-change: transform, clip-path;
    transform: translateY(50px);
    clip-path: inset(100% 0% 0% 0%);
    transition: clip-path 1.2s $e_slot,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  .is-active & {
    img {
      clip-path: inset(0% 0% 0% 0%);
      transform: translateY(0);
    }
  }
}


.kv_figure_sub_item:nth-child(1) {
  img {
    will-change: transform, clip-path;
    transform: translateX(100px);
    clip-path: inset(0% 100% 0% 0%);
    transition: clip-path 1.8s $e_slot .4s,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  .is-active & {
    img {
      clip-path: inset(0% 0% 0% 0%);
      transform: translateX(0);
    }
  }
}

.kv_figure_sub_item:nth-child(2) {
  img {
    will-change: transform, clip-path;
    transform: translateX(100px);
    clip-path: inset(0% 100% 0% 0%);
    transition: clip-path 1.8s $e_slot .4s,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  .is-active & {
    img {
      clip-path: inset(0% 0% 0% 0%);
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.kv_figure_sub_item:nth-child(3) {
  img {
    will-change: transform, clip-path;
    transform: translateX(-100px);
    clip-path: inset(0% 0% 0% 100%);
    transition: clip-path 1.8s $e_slot .4s,
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  .is-active & {
    img {
      clip-path: inset(0% 0% 0% 0%);
      transform: translateX(0);
    }
  }
}

